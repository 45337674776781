<template>
  <div>
    <a-spin :spinning="loading">
      <Header title="影城会员卡详情" @back="onBack(false)"></Header>

      <div style="margin: 20px 0">
        <!-- <a-row>
          <a-col :span="12">会员卡号：{{ record.cardNumber }}</a-col>
          <a-col :span="12" style="text-align: right"
            >累计充值金额： {{ record.total }}元</a-col
          >
        </a-row> -->
        <a-descriptions title="影城会员卡信息">
          <a-descriptions-item label="会员昵称">{{
            cardInfo.memberName
          }}</a-descriptions-item>
          <a-descriptions-item label="会员电话">{{
            cardInfo.memberPhone
          }}</a-descriptions-item>
          <a-descriptions-item label="卡号">{{
            cardInfo.cardNumber
          }}</a-descriptions-item>
          <a-descriptions-item label="余额">{{
            cardInfo.balance
          }}</a-descriptions-item>
          <a-descriptions-item label="影院">
            {{ cardInfo.cinemaName }}
          </a-descriptions-item>
          <a-descriptions-item label="绑定时间">
            {{ transDateTime(cardInfo.createTime) }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div>
        <a-descriptions title="余额变动记录"></a-descriptions>
        <a-table :dataSource="list" :columns="columns" :pagination="pagination">
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'time'">
              {{ transDateTime(record.time) }}
            </template>
          </template>
        </a-table>
      </div>

      <!-- <div>
        <a-descriptions title="消费记录"></a-descriptions>
        <a-table
          :dataSource="buyList"
          :columns="columns1"
          :pagination="pagination1"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'rcreDateTime'">
              {{ transDateTime(record.rcreDateTime) }}
            </template>
            <template v-if="column.key === 'filmShortTitle'">
              购买影票：{{record.filmShortTitle }}
            </template>
            <template v-if="column.key === 'consumeAmount'">
              {{(record.beforeBalance-record.afterBalance).toFixed(2)}}
            </template>
          </template>
        </a-table>
      </div> -->

      <div style="margin: 20px 0; text-align: center">
        <a-button type="primary" @click="onBack(false)">返回</a-button>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import Header from "@/components/header/header.vue";
import { getQueryAddMoneyList, getCardBuying } from "@/service/modules/coupon";
export default {
  components: { Icon, Header },
  props: {
    //     id: {
    //       type: Number,
    //       default: 0,
    //     },
    //     movieId: {
    //       type: Number,
    //       default: 0,
    //     },
    cardInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      record: {},
      list: [],
      buyList: [],
      data1: [
        {
          key: "1",
          name: "John Brown",
          age: 32,
          tel: "0571-22098909",
          phone: 18889898989,
          address: "New York No. 1 Lake Park",
        },
      ],
      columns: [
        {
          title: "变动前余额",
          dataIndex: "balanceBefore",
        },
        {
          title: "变动金额",
          dataIndex: "addMoney",
        },
        {
          title: "变动后余额",
          dataIndex: "balanceAfter",
        },
        {
          title: "变动时间",
          key: "time",
        },
      ],
      //
      columns1: [
        {
          title: "消费信息",
          key: "filmShortTitle",
        },
        {
          title: "消费前余额",
          dataIndex: "beforeBalance",
        },
        {
          title: "消费金额",
          key: "consumeAmount",
        },
        {
          title: "消费后金额",
          dataIndex: "afterBalance",
        },
        {
          title: "消费时间",
          key: "rcreDateTime",
        },
      ],
      //
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ["10", "20", "50", "100", "500"],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        },
      },
      pagination1: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ["10", "20", "50", "100", "500"],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination1.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination1.current = 1;
          this.pagination1.pageSize = pageSize;
          this.getData();
        },
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onBack(isRef) {
      this.$emit("back", isRef);
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getQueryAddMoneyList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          cardNumber:this.cardInfo.cardNumber,
          cinemaId: this.cardInfo.cinemaId,
        });
        this.loading = false;
        if (ret.code === 200) {
          this.list = ret.data.list
          this.pagination.total = ret.data.totalCount;
        }
      } catch (e) {
        this.loading = false;
      }
      this.getCardBuy(this.cardInfo.cardNumber);
    },
    async getCardBuy(memberCardId) {
      try {
        let res = await getCardBuying({
          page: this.pagination1.current,
          pageSize: this.pagination1.pageSize,
          cardNumber: memberCardId,
          cinemaId: this.cardInfo.cinemaId,
        });
        this.loading = false;
        if (res.code === 200) {
          this.buyList = res.data.list;
          // this.record = res.data.list[0];
          // this.buyList = ret.data.list.slice(1);
        }
      } catch (e) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
